<template>
    <div>
        <navheader></navheader>
        <div class="detailBox">
            <div class="detailBanner">

            </div>
            <div class="detailContent">
                <!-- 面包屑和搜索 -->
                <div class="top">
                    <div class="crumbs">
                        <i>
                            <img src="../assets/img/loginIcon/index-icon.png" alt="">
                        </i>
                        <a href="javascript:;">首页</a>
                        <p>></p>
                        <a href="javascript:;" class="clickActive">资讯中心</a>
                    </div>
                    <!-- <div class="searchInput">
                        <el-input
                        placeholder="请输入您感兴趣的课程"
                        clearable
                        v-model="inputValue"
                        @keyup.enter.native="toSearch"
                        >
                            <el-button class="searchBtn"
                                        size="mini"
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="toSearch"
                            ></el-button>
                        </el-input>
                    </div> -->
                </div>
                <div class="contentBox">
                    <div class="leftSlide">
                        <div class="leftTitle">
                            <p class="top-title">{{detailContent.title}}</p>
                            <p class="sub-title"><span>{{detailContent.keyword}}</span><span>责任编辑：{{detailContent.editor}}</span><span>{{detailContent.publishDate}}</span></p>
                        </div>
                        <div class="adBox" style="display: none;">
                            <div class="adImg"></div>
                            <div class="adText">
                                <p class="top-adText">
                                    网校云9年软考培训经验，助您顺利通过2020年软件水平考试>>
                                </p>
                                <p class="sub-adText">
                                    学习推荐
                                </p>
                            </div>
                            <a href="javascript:;" class="adButton">
                                免费试听
                            </a>
                        </div>
                        <div class="leftContentBox" v-html="detailContent.content">
                            {{detailContent.content}}
                        </div>
                        <div class="leftJumpBox">
                            <div>
                                <a href="javascript:;" v-if="detailJump.preId == -1">
                                    上一篇：已无资讯
                                </a>
                                <a href="javascript:;" v-else @click="preJump(detailJump.preId)">
                                    上一篇：{{detailJump.preTitle}}
                                </a>
                            </div>
                            <div>
                                <a href="javascript:;" v-if="detailJump.afterId == -1">
                                    下一篇：已无资讯
                                </a>
                                <a href="javascript:;" v-else @click="afterJump(detailJump.afterId)">
                                    下一篇：{{detailJump.afterTitle}}
                                </a>
                            </div>
                        </div>
                        <div class="leftRecommend">
                            <div class="recommendTitle">
                                <div class="border"></div><p>相关文章</p>
                            </div>
                            <div class="recommendBox">
                                <div class="recommendSlide" v-for="(item,index) in relativeList" :key="index">
                                    <a href="javascript:;" @click="recommendJump(item.id)">
                                        <div class="imgBox">
                                            <img :src="item.logoUrl" alt="">
                                        </div>
                                        <div class="textBox">
                                            <p class="title">{{item.title}}</p>
                                            <p class="text">{{item.description}}</p>
                                            <p class="date">{{item.publishDate}}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hotnews></hotnews>
                </div>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>

<script>
import hotnews from '../components/HotNews'
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
export default {
    data() {
        return {
            id: 0,
            detailContent: {},
            detailJump: {},
            inputValue: '',
            relativeList: [],
            categoryId: 0
        }
    },
    metaInfo: {
        title: '新闻详情-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    components: {
        navheader,
        navfooter,
        hotnews
    },
    created() {
        this.id = this.$route.params.id;
        this.categoryId = this.$route.params.categoryId;
    },
    // mounted() {
    //     this.getHot();
    // },
    watch: {
        $route(){
            this.id = this.$route.params.id;
            this.categoryId = this.$route.params.categoryId;
        },
        id() {
            this.getDetail()
        }
    },
    methods: {
        recommendJump(id) {
            this.$router.push({
                name: 'newsdetail',
                params:{id,categoryId: -3}
            })
        },
        preJump(id) {
            // console.log(id)
            this.$router.push({
                name: 'newsdetail',
                params:{id,categoryId: this.categoryId}
            })
        },
        afterJump(id) {
            this.$router.push({
                name: 'newsdetail',
                params:{id,categoryId: this.categoryId}
            })
        },
        getDetail() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/detail.html',
                data: {
                    id: this.id,
                    categoryId: this.categoryId
                }
            }).then((res) => {
                this.detailContent = res.data.result.information;
                this.detailJump = res.data.result;
                this.relativeList = res.data.result.relativeList;

            })
        },
        // toSearch() {
        //     if (this.inputValue == '') return this.$message.error("搜索关键字为空!")
        //     // this.$store.commit("setSearch", this.inputValue);
        // },
    }
}
</script>
<style lang="scss">
    .detailBox {
        margin-top: 70px;
        width: 100%;
        min-width: 1200px;
        padding-bottom: 80px;
        box-sizing: border-box;
        .detailBanner {
            margin-bottom: 60px;
            width: 100%;
            height: 150px;
            background: url("../assets/img/newsBanner.jpg") no-repeat;
        }
        .detailContent {
            margin: 0 auto;
            width: 1200px;
            .top {
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;
                .crumbs {
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        margin-right: 5px;
                        width: 14px;
                        height: 14px;
                    }
                    p {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                    a {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        display: inline-block;
                        color: #666666;
                    }
                    .clickActive {
                        color: #FF5A74;
                    }
                }
                .searchInput {
                    width: 431px;
                    .el-input__inner {
                        width: 343px;
                        height: 48px;
                        border: 1px solid #FF5A74;
                        border-radius: 5px 0px 0px 5px;
                    }
                    .el-input-group__append {
                        padding: 0 41px;
                        background: #FF5A74;
                        border-radius: 0px 5px 5px 0px;
                        border: 1px solid #FF5A74;
                        .el-icon-search {
                            color: #ffffff;
                        }
                    }
                }
            }
            .contentBox {
                display: flex;
                justify-content: space-between;
                .leftSlide {
                    width: 839px;
                    .leftTitle {
                        margin-bottom: 28px;
                        .top-title {
                            margin-bottom: 16px;
                            font-size: 30px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }
                        .sub-title {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                            span {
                                margin-right: 40px;
                            }
                        }
                    }
                    .adBox {
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;
                        padding: 15px 42px;
                        box-sizing: border-box;
                        width: 839px;
                        height: 100px;
                        border: 1px solid #EEEEEE;
                        border-radius: 2px;
                        .adImg {
                            margin-right: 15px;
                            width: 70px;
                            height: 70px;
                            background: blue;
                        }
                        .adText {
                            margin-right: 120px;
                            .top-adText {
                                margin-bottom: 5px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                            .sub-adText {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                        .adButton {
                            width: 90px;
                            height: 36px;
                            background: #FA4B66;
                            border-radius: 3px;
                            text-align: center;
                            line-height: 36px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                    .leftContentBox {
                        padding-bottom: 23px;
                        box-sizing: border-box;
                        margin-bottom: 37px;
                        border-bottom: 1px solid #EEEEEE;
                        .contentText {
                            margin-bottom: 28px;
                            text-indent:2em;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;                     
                            line-height: 25px;
                        }
                        .contentTextBottom {
                            margin-bottom: 37px;
                            text-align: center;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .leftJumpBox {
                        margin-bottom: 93px;
                        a {
                            display: block;
                            margin-bottom: 20px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .leftRecommend {
                        .recommendTitle {
                            margin-bottom: 30px;
                            display: flex;
                            align-items: center;
                            .border {
                                margin-right: 10px;
                                width: 4px;
                                height: 16px;
                                background: #FA4B66;
                            }
                            p {
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                        }
                        .recommendBox {
                            .recommendSlide {
                                margin-bottom: 20px;
                                padding-bottom: 20px;
                                box-sizing: border-box;
                                border-bottom: 1px solid #EEEEEE;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                a {
                                    display: flex;
                                    align-items: center;
                                    .imgBox {
                                        margin-right: 20px;
                                        width: 200px;
                                        height: 125px;
                                        // background: blue;
                                    }
                                    .textBox {
                                        padding-top: 10px;
                                        width: 615px;
                                        .title {
                                            margin-bottom: 7px;
                                            font-size: 18px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #333333;
                                        }
                                        .text {
                                            margin-bottom: 11px;
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #666666;
                                        }
                                        .date {
                                            font-size: 14px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #999999;
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
</style>