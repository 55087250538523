<template>
    <div>
        <div class="rightSlide">
            <div class="slideTitle">
                <p>热门推荐</p>
            </div>
            <div class="slideContent">
                <ul>
                    <li v-for="(item,index) in hotList" :key="index">
                        <a href="javascript:;" @click="learnNow(item.id)">{{item.title}}</a>
                    </li>
                </ul>
            </div>
            <div class="hotImg">
                <a href="/#/Live">
                    <div class="liveCenter">
                        <p class="title">直播中心</p>
                        <div class="line"></div>
                        <p class="subTitle">直播+录播双向教学</p>
                    </div>
                </a>
                <a href="/#/QuestionBank">
                    <div class="onlineQuestionBank">
                        <p class="title">在线题库</p>
                        <div class="line"></div>
                        <p class="subTitle">历年真题自测估分</p>
                    </div>
                </a>
                <a href="/#/ClassList">
                    <div class="curriculumCenter">
                        <p class="title">课程中心</p>
                        <div class="line"></div>
                        <p class="subTitle">零基础在线学习</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hotList: [],
            type: -2
        }
    },
    mounted() {
        this.getHot();
    },
    methods: {
        learnNow(id) {
            this.$router.push({
                name: 'newsdetail',
                params:{id,categoryId: this.type}
            })
        },
        getHot() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/getCommend.html'
            }).then((res) => {
                this.hotList = res.data.result.commendList;
            })
        },
    }
}
</script>
<style scoped lang="scss">
    .rightSlide {
            padding: 17px 19px;
            box-sizing: border-box;
            width: 340px;
            background: #FFFFFF;
            border: 1px solid #EEEEEE;
            .slideTitle {
                padding-top: 17px;
                padding-bottom: 17px;
                box-sizing: border-box;
                margin: 0 auto;
                width: 300px;
                border-bottom: 1px solid #EEEEEE;
                p {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
            }
            .slideContent {
                margin: 0 auto;
                margin-bottom: 40px;
                width: 300px;
                padding-top: 24px;
                box-sizing: border-box;
                ul {     
                    padding-left: 17px;
                    box-sizing: border-box;
                    li {
                        margin-bottom: 20px;
                        list-style: circle;
                        a {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                }
            }
            .hotImg {
                    .liveCenter {
                        margin-bottom: 10px;
                        padding: 29px 30px;
                        box-sizing: border-box;
                        width: 300px;
                        height: 110px;
                        background: url("../assets/img/news-live.jpg") no-repeat;
                        .title {
                            margin-bottom: 5px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }
                        .line {
                            margin-bottom: 15px;
                            width: 18px;
                            height: 2px;
                            background: #FA4B66;
                        }
                        .subTitle {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                    .onlineQuestionBank {
                        margin-bottom: 10px;
                        padding: 29px 30px;
                        box-sizing: border-box;
                        width: 300px;
                        height: 110px;
                        background: url("../assets/img/news-question.jpg") no-repeat;
                        .title {
                            margin-bottom: 5px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }
                        .line {
                            margin-bottom: 15px;
                            width: 18px;
                            height: 2px;
                            background: #FA4B66;
                        }
                        .subTitle {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                    .curriculumCenter {
                        padding: 29px 30px;
                        box-sizing: border-box;
                        width: 300px;
                        height: 110px;
                        background: url("../assets/img/news-class.jpg") no-repeat;
                        .title {
                            margin-bottom: 5px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }
                        .line {
                            margin-bottom: 15px;
                            width: 18px;
                            height: 2px;
                            background: #FA4B66;
                        }
                        .subTitle {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                }
        }
</style>